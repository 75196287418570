export default {
    methods: {
        /**
         * @param dateString
         * @returns {string} 22.02.2022
         */
        toDate(dateString, style = "medium") {
            let date = dateString
            if (!(dateString instanceof Date)) {
                date = new Date(dateString)
            }
            if (date) {
                return new Intl.DateTimeFormat("de", {
                    dateStyle: style,
                }).format(date)
            }
        },
        /**
         * @param dateString
         * @returns {string} 11:22
         */
        toTime(dateString, style = "short") {
            let date = dateString
            if (!(dateString instanceof Date)) {
                date = new Date(dateString)
            }
            return new Intl.DateTimeFormat("de", {
                timeStyle: style,
            }).format(date)
        },
        /**
         * @param dateString
         * @returns {string} 22.02.2022 11:22
         */
        toDatetime(dateString) {
            let date = dateString
            if (date === undefined) {
                return "-"
            }
            if (!(dateString instanceof Date)) {
                date = new Date(dateString)
            }
            return new Intl.DateTimeFormat("de", {
                dateStyle: "medium",
                timeStyle: "short",
            }).format(date)
        },
        /**
         * @param dateString1
         * @param dateString2
         * @returns {string} 22.02.2022 - 23.02.2022
         */
        toDateInterval(dateString1, dateString2) {
            let date1 = dateString1
            if (!(dateString1 instanceof Date)) {
                date1 = new Date(dateString1)
            }
            let date2 = dateString2
            if (!(dateString2 instanceof Date)) {
                date2 = new Date(dateString2)
            }
            let result = new Intl.DateTimeFormat("de", {
                dateStyle: "medium",
            }).format(date1)
            result =
                result +
                " - " +
                new Intl.DateTimeFormat("de", {
                    dateStyle: "medium",
                }).format(date2)
            return result
        },
        /**
         * @param dateString1
         * @param dateString2
         * @returns {string} 22 - 23
         *
         */
        toDateIntervalDays(dateString1, dateString2) {
            let date1 = dateString1
            if (!(dateString1 instanceof Date)) {
                date1 = new Date(dateString1)
            }
            let date2 = dateString2
            if (!(dateString2 instanceof Date)) {
                date2 = new Date(dateString2)
            }
            const day1 = new Intl.DateTimeFormat("de")
                .format(date1)
                .split(".")[0]
            const day2 = new Intl.DateTimeFormat("de")
                .format(date2)
                .split(".")[0]
            return day1 + " - " + day2
        },
        /**
         * formats given date with the given options
         * https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
         * @param dateString
         * @param options
         * @returns {string}
         */
        formatDate(dateString, options) {
            let date = dateString
            if (!(dateString instanceof Date)) {
                date = new Date(dateString)
            }
            return new Intl.DateTimeFormat("de", options).format(date)
        },
        /** Expects: Date object + TimeString HH:MM
         * Returns: Date object with time added
         */
        addTime(dateObject, timeString) {
            try {
                const [hours, minutes] = timeString.split(":")
                dateObject.setHours(hours)
                dateObject.setMinutes(minutes)
            } catch (err) {
                console.warn(err)
            }
            return dateObject
        },
    },
}
