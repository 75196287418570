
import Timestamp from "@/components/base/card/Timestamp"
export default {
    name: "TimestampBlok",
    components: { Timestamp },
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
}
