
import dates from "@/mixins/dates"

export default {
    name: "Timestamp",
    mixins: [dates],
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Date],
            required: true,
        },
        to: {
            type: [String, Date],
            default: null,
        },
        showTime: {
            type: Boolean,
            default: true,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                "white--text black": this.dark,
                "black--text white": !this.dark,
                large: !!this.showYear,
                wide: !!this.to,
                vertical: this.vertical,
                //"flex-column": this.vertical,
            }
        },
        timestamp() {
            return new Date(this.value)
        },
        timestampTo() {
            return new Date(this.to)
        },
        /* Return only if more than 1 year apart from now */
        showYear() {
            const diffFrom =
                this.timestamp.getFullYear() !== new Date().getFullYear()
            const diffTo = this.to
                ? this.timestampTo.getFullYear() !== new Date().getFullYear()
                : false
            return diffTo || diffFrom
        },
    },
}
